.login-navbar {
    width: 100%
}

.login-navbar-right-panel {
    width: 100%; 
    height: 36px; 
    position: relative;
}

.login-navbar-language-selector{
    width: 36px;
    height: 36px; 
    right: 0;
    top: 0;
    position: absolute;
    filter: invert(1);
}

.navbar {
    width: 100%;
    height: auto;
    padding-top: 27px;
    padding-bottom: 27px; 
    padding-left: 125px;
    padding-right: 70px;
    color: #FFFFFF; 
    background: #282c34; 
    box-shadow: 0px 10px 10px rgba(235, 235, 237, 0.16); 
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    justify-content: space-between; 
    align-items: center; 
    display: inline-flex;
    margin-bottom: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
}

.navbar-right-panel {
    width: 100%; 
    height: 36px; 
    position: relative;
}

.navbar-language-selector{
    width: 36px;
    height: 36px; 
    right: 68px;
    top: 0;
    position: absolute;
    filter: invert(1);
}

.navbar-logout {
    width: 36px;
    height: 36px; 
    right: 0;
    top: 0;
    position: absolute;
    filter: invert(1);
}

.nav-link {
    width: 36px;
    height: 36px;
    float: inline-end;
}

