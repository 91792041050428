.sidebar-container {
    width: 240px;
    overflow: scroll;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 25px;
    display: inline-flex;
    padding-bottom: 10px;
    padding-left: -10px;
}

.sidebar-filter {
    width: 100%;
    height: auto;
    flex-direction: column;
    justify-content: flex-start; 
    align-items: flex-start;
    gap: 9px;
    display: flex;
}

.sidebar-filter-title {
    color: #FFFFFF;
    align-self: stretch; 
    font-size: 16px; 
    font-family: "Roboto"; 
    font-weight: 500; 
    word-wrap: break-word;
    padding-left: 10px;
}

.sidebar-filter-container {
    width: 100%;
    height: auto;
    flex-direction: column;
    justify-content: flex-start; 
    align-items: flex-start;
    gap: 9px;
    display: flex;
    padding-left: 10px;
}

a {
    text-decoration: none;
}

.sidebar-info {
    height: auto;
    flex-direction: column;
    justify-content: flex-start; 
    align-items: flex-start;
    gap: 8px;
    display: flex;
    text-align: left;
}

.sidebar-return-link {
    align-self: stretch; 
    font-size: 16px; 
    font-family: "Roboto";
    font-weight: 300; 
    word-wrap: break-word;
    color: #D46060;
}

.sidebar-info-container {
    align-self: stretch;
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
}

.sidebar-info-item {
    align-self: stretch;
    font-family: "Roboto";
    word-wrap: break-word;
}

.sidebar-info-title{
    font-size: 20px;
    font-weight: 700;
    text-transform: capitalize;
}

.sidebar-info-text {
    font-size: 16px;
    font-weight: 300;
    text-transform: capitalize;
}