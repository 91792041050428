.App-logo {
  height: 40vmin;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
      animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
color: #61dafb;
}

span{
background: transparent !important
}

span:hover {
background: transparent !important
}

.normal-tag {
width: auto;
height: auto;
padding-left: 13px;
padding-right: 13px;
padding-top: 10px;
padding-bottom: 10px;
line-height: 19px;
background: rgba(255, 255, 255, 0.35);
border-radius: 50px;
overflow: hidden; 
justify-content: center; 
align-items: center;
gap: 10px;
display: inline-flex;
}

.normal-tag-text {
font-size: 16px; 
font-family: "Roboto";
font-weight: 500;
word-wrap: break-word;
}

.selected-tag {
width: auto;
height: auto; 
padding-left: 13px;
padding-right: 13px;
padding-top: 10px;
padding-bottom: 10px;
line-height: 19px;
background: #ffffff;
border-radius: 50px; 
overflow: hidden; 
justify-content: center; 
align-items: center; 
gap: 10px;
display: inline-flex;
}

.selected-tag-text {
font-size: 16px; 
font-family: "Roboto";
font-weight: 500; 
word-wrap: break-word;
}

.n7-button {
padding: 13px 20px;
line-height: 19px;
border-radius: 6px;
border: none;
overflow: hidden;
justify-content: center; 
align-items: center; 
gap: 10px;
display: inline-flex;
font-size: 16px;
font-family: "Roboto"; 
font-weight: 500;
word-wrap: break-word;
}

.normal-button {
background: #ffffff;
}

.normal-button-hover {
background: rgba(222.06, 222.06, 222.06, 0.62);
}

.disabled-button {
opacity: 0.50;
background: #ffffff
}

.cancel-button {
background: #D46060;
color: #ffffff;
}

.cancel-button-hover { 
background: #C05858;
color: #ffffff;
}

.delete-button {
background: #ffffff;
color: #D46060;
}

.delete-button-hover {
background: rgba(222.06, 222.06, 222.06, 0.62);
color: #D46060;
}