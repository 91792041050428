.error-container {
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: flex-start;
    display: inline-flex;
    background-color: #000000;
}

.error-main-panel {
    height: 100%; 
    padding-top: 100px;
    padding-bottom: 100px; 
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 28px;
    display: inline-flex
}

.error-img {
    width: 150px; 
    height: 150px;
}

.error-info-panel {
    flex-direction: column; 
    justify-content: flex-start;
    align-items: flex-start; 
    gap: 42px; 
    display: flex
}

.error-type {
    width: 450px;
    color: #F2F2F2;
    font-size: 32px;
    font-family: "Roboto"; 
    font-weight: 700;
    word-wrap: break-word;
}

.error-msg-panel {
    align-self: stretch; 
    height: auto; 
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; 
    gap: 11px; 
    display: flex
}

.error-img-title {
    align-self: stretch; 
    color: #F2F2F2;
    font-size: 20px; 
    font-family: "Roboto"; 
    font-weight: 500; 
    word-wrap: break-word;
}

.error-msg {
    align-self: stretch;
    color: #F2F2F2;
    font-size: 16px;
    font-family: "Roboto"; 
    font-weight: 300; 
    word-wrap: break-word;
}