.title-panel {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 42px;
    display: flex;
    text-align: left;
}

.title-panel-container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    display: flex;
}

.title {
    color: #FFFFFF;
    width: 450px;
    font-size: 32px;
    font-family: "Roboto";
    font-weight: 700;
    word-wrap: break-word;
}

.content-info-container {
    align-self: stretch;
    height: 53px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 11px;
    display: flex;
}

.content-info-main {
    align-self: stretch;
    font-size: 20px;
    font-family: "Roboto";
    font-weight: 500;
    word-wrap: break-word;
}

.purchase-msg {
    width: 450px;
    font-size: 20px;
    font-family: "Roboto";
    font-weight: 700;
    word-wrap: break-word;
    margin-bottom: 42px;
}

.content-info-secondary {
    align-self: stretch;
    font-size: 16px;
    font-family: "Roboto";
    font-weight: 300;
    word-wrap: break-word
}