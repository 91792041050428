.navbar + .main-panel-with-footer {
    padding-top: 164px;
}

.main-panel-with-footer {
    width: 100%;
    height: auto;
    min-height: 100%;
    max-height: 100%;
    background-color: #282c34;
    padding-left: 70px; 
    padding-right: 70px; 
    display: flex;
    justify-content: center;
}

.main-panel-container {
    width: 100%;
    height: auto;
    justify-content: flex-start;
    align-items: flex-start; 
    gap: 70px; 
    display: inline-flex;
}

.video-page-container {
    width: 900px;
    padding: 0 0 60px 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 42px;
    display: inline-flex;
    margin-bottom: 72px;
}

.table-container {
    width: 100%;
    display: block;
    overflow: scroll;
}

.table {
	--bs-table-bg: unset; 
    border-collapse: collapse;
}

.table > thead {
    border-bottom: #FFFFFF solid 2px;
    position: sticky;
    top: 0;
    z-index: 999;
    background-color: #282c34;
}

thead, tbody > tr {
    border-bottom: #FFFFFF solid 1px;
}

.table > thead > tr > th {
    color: #FFFFFF;
    font-size: 20px; 
    font-family: "Roboto";
    font-weight: 700;
    word-wrap: break-word;
    height: 80px;
    text-align: left;
    justify-content: flex-start;
    align-items: center;
    padding: 0 85px 0 0;
}

.table > tbody > tr > td {
    color: #FFFFFF;
    font-size: 16px;
    font-family: "Roboto"; 
    font-Weight: 500; 
    word-wrap: break-word;
    text-align: left;
    padding-left: 0;
}

.table > tbody > tr:last-child td {
    border: none;
}

.table-action-buttons {
    margin-right: 20px;
}

.table-information-panel {
    position: relative;
}

.purchase-page-container {
    width: 900px;
    overflow: scroll;
    padding: 0 0 60px 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 42px;
    display: inline-flex;
    margin-bottom: 72px;
}

.purchase-form-container {
    width: 100%;
    height: auto;
    display: inline-flex;
    padding: 24px 26px;
    flex-direction: column;
    align-items: flex-start;
    gap: 28px;
    flex: 1 0 0;
    border-radius: 6px;
    background: white;
}

label {
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
}

.ant-form-vertical .ant-form-item-label > label {
	height: 19px;
}

.ant-form-item .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
	all: unset;
}

.ant-input {
	all: unset;
}

.ant-input:focus, .ant-input-focused {
	all: unset;
}

.ant-picker {
	border: none;
    padding: 0;
}

.ant-picker:hover, .ant-picker-focused {
	border: none;
    box-shadow: none;
}

.ant-picker .ant-picker-input > input:focus, .ant-picker .ant-picker-input > input-focused {
	border: none;
    box-shadow: none;
}

.ant-picker:not(.ant-picker-disabled).ant-picker-status-error.ant-picker-focused, .ant-picker:not(.ant-picker-disabled).ant-picker-status-error:focus {
	border: none;
    box-shadow: none;
}

.ant-picker .ant-picker-suffix {
	display: none;
}

.ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label {
	padding: 0 0 12px 0;
	white-space: initial;
	text-align: start;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
	border: none;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus {
	border: none;
    box-shadow: none;
}

.ant-input[disabled]{
    all: unset
}

.ant-form-item {
	margin-bottom: 0;
}

.ant-form-item .ant-form-item-control-input {
	position: relative;
	display: flex;
	align-items: center;
	min-height: 19px;
    line-height: 19px;
}

input[id^="buy-content"] {
    background-color: none;
    border: none;
}

input[id^="buy-content"]::placeholder {
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    color: var(--input-color);
}

input#buy-content_expiryDate {
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    color: var(--input-color);
}

input#buy-content_expiryDate::placeholder {
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    color: var(--input-color);
}